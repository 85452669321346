import * as THREE from "three";
import gsap from "gsap";

import { CameraPosition, GetState } from "Interfaces/StateInterface.js";
import { SetAnimationFunctions } from "Interfaces/AnimationInterface.js";

export default function PerspectiveCamera(FOV, near, far, position, lookAt){

    const camera = new THREE.PerspectiveCamera(FOV, window.innerWidth / window.innerHeight, near, far);
    camera.position.set(position.x, position.y, position.z);     
    camera.lookAt(new THREE.Vector3(lookAt.x, lookAt.y, lookAt.z));

    CameraPosition.x = position.x;
    CameraPosition.y = position.y;
    CameraPosition.z = position.z;
    CameraPosition.camera = camera;

    function SetCameraPosition(position){
        camera.position.set(position);
        CameraPosition.x = position.x;
        CameraPosition.y = position.y;
        CameraPosition.z = position.z;
    } 
    
    function SetCameraLookAt(lookAt){
        camera.lookAt(lookAt);
    }

    function SetCametaFOV(FOV){
        camera.fov = FOV;
    }

    function SetCameraOffset(offset){
        const x = position.x + offset.x;
        const y = position.y + offset.y;
        const z = position.z;

        camera.position.set(x, y, z);

        CameraPosition.x = x;
        CameraPosition.y = y;
        CameraPosition.z = z;
    }

    function AnimateCamera(position, duration, delay){
        gsap.to(camera.position, {
            duration: duration,
            delay: delay,
            x: position.x,
            y: position.y,
            z: position.z
        });
    }

    SetAnimationFunctions(AnimateCamera, "CameraPosition");

    function AnimateLookAt(position, duration, delay){
        gsap.to(camera.lookAt, {
            duration: duration,
            delay: delay,
            x: position.x,
            y: position.y,
            z: position.z            
        });
    }

    window.addEventListener('resize', () => {    
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();        
    });    


    // Camera movement
    const cursorRelativeCoordinates = {
        x: 0,
        y: 0
    }   
    window.addEventListener('mousemove', (event) => {
        const CurrentState = GetState();
        if(CurrentState.cameraOffset){
            cursorRelativeCoordinates.x = (event.clientX / window.innerWidth * 2 - 1) *  -0.2;
            cursorRelativeCoordinates.y = (event.clientY / window.innerHeight * 2 - 1) * 0.2;         
            SetCameraOffset(cursorRelativeCoordinates);
        }
    });

    return {
        cameraActor: camera,
        SetCameraPosition,
        SetCameraLookAt,
        SetCametaFOV,
        AnimateCamera,
        AnimateLookAt,
        SetCameraOffset
    }
}