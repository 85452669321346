export function Particles00VertexShader(){
    return `
    uniform float uTime;
    uniform float uSize;
	uniform vec3 uParticleColor;
    uniform vec3 uParticleColor2;
    uniform float uColorLerp;
    uniform float uParticleAlpha;

    varying vec3 vColor;
    varying vec3 vColor2;
    varying float vColorLerp;
    varying float vParticleAlpha;
    varying float vVertexPosZ;

	void main() {
        vColor = uParticleColor;   
        vColor2 = uParticleColor2;
        vColorLerp = uColorLerp;
        vParticleAlpha = uParticleAlpha;

        vec4 modelPosition = modelMatrix * vec4( position, 1.0 );
        modelPosition.x += sin(uTime + (modelPosition.y * 0.2 )) * 3.0;
        modelPosition.y += sin(uTime + (modelPosition.z * 0.3 )) * 3.0;
        vVertexPosZ = modelPosition.z;
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
        
        gl_Position = projectionPosition;
        gl_PointSize = uSize * (1.0 / -viewPosition.z);
    }
    `;
}

export function Particles00FragmentShader(){
    return `  
        varying vec3 vColor;  
        varying vec3 vColor2;
        varying float vColorLerp;
        varying float vParticleAlpha;
        varying float vVertexPosZ;      

        void main() {
            float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
            float clamppedDepth = clamp(1.0 - (vVertexPosZ * -0.03), 0.0, 1.0);
            float alphaStrength = clamppedDepth * (0.4 - distanceToCenter) * vParticleAlpha;
            vec3 lerpColor = vColor * (1.0 - vColorLerp) + vColor2 * vColorLerp;
            gl_FragColor = vec4( lerpColor, alphaStrength );
        }
    `;
}

export function Particles01VertexShader(){
    return `
        uniform float uTime;
        uniform float uSize;
        uniform vec3 uParticleColor;
        uniform vec3 uParticleColor2;
        uniform float uParticleAlpha;

        varying vec3 vColor;
        varying vec3 vColor2;
        varying float vParticleAlpha;
        varying float vLerpIntensity;        

        void main() {
            vColor = uParticleColor;   
            vColor2 = uParticleColor2;
            vParticleAlpha = uParticleAlpha;
    
            vec4 modelPosition = modelMatrix * vec4( position, 1.0 );
            vLerpIntensity = fract(modelPosition.x + modelPosition.y);
            modelPosition.x += sin((uTime * 0.1) + (modelPosition.y * 0.2 )) * 3.0;
            modelPosition.y += sin((uTime * 0.1) + (modelPosition.z * 0.3 )) * 3.0;
            modelPosition.z += sin((uTime * 0.1) + (modelPosition.x * 0.3 )) * 3.0;
            vec4 viewPosition = viewMatrix * modelPosition;
            vec4 projectionPosition = projectionMatrix * viewPosition;
            
            gl_Position = projectionPosition;
            gl_PointSize = uSize * (1.0 / -viewPosition.z) + vLerpIntensity * 5.0;
        }

    `;
}

export function Particles01FragmentShader(){
    return `
        varying vec3 vColor;
        varying vec3 vColor2;
        varying float vParticleAlpha;
        varying float vLerpIntensity; 

        void main() {                      
            vec3 lerpColor = vColor * (1.0 - vLerpIntensity) + vColor2 * vLerpIntensity;
            float distanceToCenter = 1.0 - distance(gl_PointCoord, vec2(0.5));
            float alphaStrength = (clamp(1.0 - ((1.4 - distanceToCenter)), 0.0, 1.0) * vParticleAlpha) * 0.8;
            gl_FragColor = vec4( lerpColor, alphaStrength );
        }
    `;
}