import { AnimateCameraPosition, ShowParticle00, HideParticle00, ChangeColorParticle00_0, ChangeColorParticle00_1, ShowParticle01, HideParticle01 } from "Interfaces/AnimationInterface.js";

const CurrentState = {
    state: 0,
    transitioning: false,
    cameraOffset: true
}

export const CameraPosition = {
    camera: null
}

export const Animations = {
    anim00: [],
    anim01: [],
    anim02: [],
    anim03: [],
    anim00reverse: [],
    anim01reverse: [],
    anim02reverse: [],
    anim03reverse: []
}

const StateEnum = {
    0: "loading",
    1: "screen1",
    2: "screen2",
    3: "screen3",
    4: "screen4",
    5: "screen5",
    6: "screen6",
    7: "screen7"
}

const StateObservers = {
    loading: [],
    screen1: [],
    screen2: [],
    screen3: [],
    screen4: [],
    screen5: [],
    screen6: [],
    screen7: [],
}

const StatePositionObservers = {
    loading: [],
    screen1: [],
    screen2: [],
    screen3: [],
    screen4: [],
    screen5: [],
    screen6: [],
    screen7: [],
}

export function RegisterStateObserver(state, observer){
    StateObservers[state].push(observer);
}

export function RegisterStatePositionObserver(state, observer){    
    StatePositionObservers[state].push(observer);
}

export function GetState(){
    return CurrentState;
}

export function NextState(){
    if(!CurrentState.transitioning  && CurrentState.state < 8){
        CurrentState.transitioning = true;
        const nextState = StateEnum[(CurrentState.state + 1).toString()];
        StateObservers[nextState].forEach(element => element());        
        switch(CurrentState.state){            
            case 1:                
                Animations.anim00.forEach((anim, index) =>{
                    if(index === 0){
                        anim.play();
                    }else{
                        anim.stop();
                    }
                });
                break;
            case 2:                
                Animations.anim01.forEach((anim, index) =>{
                    if(index === 0){
                        anim.play();
                    }else{
                        anim.stop();
                    }
                });
                ShowParticle01();
                break;
            case 3:                
                Animations.anim02.forEach((anim, index) =>{
                    if(index === 0){
                        anim.play();
                    }else{
                        anim.stop();
                    }
                });
                CurrentState.cameraOffset = true;
                HideParticle01();
                ShowParticle00();
                break;
            case 4:
                AnimateCameraPosition({x: 0.0, y: 0.8, z: 10.0}, 1.0, 0.0);
                CurrentState.cameraOffset = false;
                ChangeColorParticle00_1();
                break;
            case 5:                
                HideParticle00();
                break;
            default:
                break;
        }
        CurrentState.state++; 
        // console.log("Next state: " + nextState);
        setTimeout(() => {
            CurrentState.transitioning = false;
        }, 1500);
    }
}

export function PreviousState(){
    if(!CurrentState.transitioning && CurrentState.state > 1){
        CurrentState.transitioning = true;
        const previousState = StateEnum[(CurrentState.state - 1).toString()];
        StateObservers[previousState].forEach(element => element());
        switch(CurrentState.state){
            case 2:                
                Animations.anim00reverse.forEach((anim, index) =>{
                    if(index === 0){
                        anim.play();
                    }else{
                        anim.stop();
                    }
                });
                break;
            case 3:                
                Animations.anim01reverse.forEach((anim, index) =>{
                    if(index === 0){
                        anim.play();
                    }else{
                        anim.stop();
                    }
                });
                HideParticle01();
                break;
            case 4:                
                Animations.anim02reverse.forEach((anim, index) =>{
                    if(index === 0){
                        anim.play();
                    }else{
                        anim.stop();
                    }
                });
                CurrentState.cameraOffset = true;
                ShowParticle01();
                HideParticle00();
                break;
            case 5: 
                AnimateCameraPosition({x: 0.0, y: 0.8, z: 27.0}, 1.0, 0.0);
                setTimeout(() => {
                    CurrentState.cameraOffset = true;
                }, 1000);
                ChangeColorParticle00_0();                
                break;
            case 6: 
                ShowParticle00();                
                break;
            default:
                break;
            
        }
        CurrentState.state--; 
        // console.log("Previous state: " + previousState);
        setTimeout(() => {
            CurrentState.transitioning = false;
        }, 1500);
    }
}

export function UpdatePosition(){
    const proportion = window.innerWidth / 1920;    
    StatePositionObservers[StateEnum[CurrentState.state.toString()]].forEach(element => element(CameraPosition.camera, proportion));
}