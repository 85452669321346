const AnimationFunctions = {
    CameraPosition: null,
    Particle00Show: null,
    Particle00Hide: null,
    Particle00ChangeColor0: null,
    Particle00ChangeColor1: null,
    Particle01Show: null,
    Particle01Hide: null,
}

export function SetAnimationFunctions(func, name){
    AnimationFunctions[name] = func;
}

export function AnimateCameraPosition(position, duration, delay){
    AnimationFunctions.CameraPosition(position, duration, delay);
}

export function ShowParticle00(){
    AnimationFunctions.Particle00Show();
}

export function HideParticle00(){
    AnimationFunctions.Particle00Hide();
}

export function ChangeColorParticle00_0(){
    AnimationFunctions.Particle00ChangeColor0();
}

export function ChangeColorParticle00_1(){
    AnimationFunctions.Particle00ChangeColor1();
}

export function ShowParticle01(){
    AnimationFunctions.Particle01Show();
}

export function HideParticle01(){
    AnimationFunctions.Particle01Hide();
}