import Stats from 'stats.js';

import { UpdatePosition }from "Interfaces/StateInterface.js";

export default function Tick(){    

    // Time variables
    let time = Date.now();
    let elapsedTime = 0;
    let deltaTime = 0;

    // Observers
    const observers = [];

    function UpdateObservers(){        
        observers.forEach(observer => {
            observer(deltaTime, elapsedTime);
            // console.log(observer);
        });
    }

    function subscribeObserver(observerFunction){
        observers.push(observerFunction);
    }

    // Debug
    const stats = new Stats();
    stats.showPanel(0);
    document.body.appendChild(stats.dom);   

    // Tick event
    function tick(){  

        // Debug start
        stats.begin();

        // Update time
        const now = Date.now();
        deltaTime = (now - time) / 1000;
        elapsedTime += deltaTime;
        time = now;

        UpdateObservers(); 
        
        UpdatePosition();
        
        // Debug
        stats.end();

        window.requestAnimationFrame(tick);
        
    }

    return {
        tick,
        subscribeObserver
    }
}