import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./styles.css";

import { RegisterStateObserver } from "Interfaces/StateInterface.js";

import { Stars } from "./svg";
import Img1 from "./assets/img1.png";
import Img2 from "./assets/img2.png";
import Img3 from "./assets/img3.png";
import Img4 from "./assets/img4.png";

export default function Screen7() {

    const [displayElement, setDisplayElement] = useState("none");
    const [opacityElement, setOpacityElement] = useState(0);

    function ShowElement(){
        setDisplayElement("flex");
        setTimeout(() => {
            setOpacityElement(1);       
        }, 500);
    }

    function HideElement(){
        setOpacityElement(0);
        setTimeout(() => {
            setDisplayElement("none");            
        }, 1000);        
    }

    useEffect(()=>{
        RegisterStateObserver("screen6", HideElement);
        RegisterStateObserver("screen7", ShowElement);
    },[]);

  return (
    <div className="secao3" style={{ display: displayElement, opacity: opacityElement }}>
      <div className="title">
        <p>
          Fones de ouvido e caixas de som para você curtir seu{" "}
          <span>#momentowaaw</span>
        </p>
      </div>
      <div className="quadrado"></div>
      <div className="carrossel">
        <div className="carrossel_itens">
          <Carousel showThumbs={false} showArrows={true}>
            <div className="carrossel_row">
              <div className="item_1">
                <div className="image">
                  <img src={Img1} alt="" />
                </div>
                <h1>SENSE 200HB</h1>
                <div>
                  <Stars />
                </div>
                <p>R$ 279</p>
                <div className="button">comprar</div>
                {/* <span>ver detalhes</span> */}
              </div>

              <div className="item_1">
                <div className="image">
                  <img src={Img2} alt="" />
                </div>
                <h1>MOB 100EB</h1>
                <div>
                  <Stars />
                </div>
                <p>R$ 249</p>
                <div className="button">comprar</div>
                {/* <span>ver detalhes</span> */}
              </div>

              <div className="item_1">
                <div className="image">
                  <img src={Img3} alt="" />
                </div>
                <h1>SENSE 300HBNC</h1>
                <div>
                  <Stars />
                </div>
                <p>R$ 849</p>
                <div className="button">comprar</div>
                {/* <span>ver detalhes</span> */}
              </div>

              <div className="item_1">
                <div className="image">
                  <img src={Img4} alt="" />
                </div>
                <h1>ENERGY 100EBG</h1>
                <div>
                  <Stars />
                </div>
                <p>R$ 269</p>
                <div className="button">comprar</div>
                {/* <span>ver detalhes</span> */}
              </div>
              

            </div>
            <div></div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
