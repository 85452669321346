import * as THREE from "three";
import { Particles00VertexShader, Particles00FragmentShader, Particles01VertexShader, Particles01FragmentShader }  from "3D/shaders/ParticlesShader.js";
import { gsap } from 'gsap';
import { SetAnimationFunctions } from "Interfaces/AnimationInterface.js";

export default function SceneParticles(group, sceneParticles, tickRegister){
    const ParticlesGeometry_00 = new THREE.PlaneGeometry(100, 50, 100, 50);

    const PixelRation = Math.min(window.devicePixelRatio, 2);
    const ParticleSize = 250.0 * PixelRation;
    let particleLerp = 0.0; 
    let particleAlpha = 0.0;

    const ParticlesMaterial_00 = new THREE.ShaderMaterial({
        uniforms: {
            uTime: { value: 0.0 },
            uParticleColor: { value: new THREE.Color( 0xe0e621 ) },
            uParticleColor2: { value: new THREE.Color( 0xffffff ) },
            uParticleAlpha: { value: particleAlpha },
            uColorLerp: { value: particleLerp },
            uSize: { value: ParticleSize }
        },
        vertexShader: Particles00VertexShader(),
        fragmentShader: Particles00FragmentShader(),
        transparent: true,        
        depthWrite: false
    });    

    const Particles_00 = new THREE.Points(ParticlesGeometry_00, ParticlesMaterial_00);
    Particles_00.rotateY(Math.PI / 1.8);
    Particles_00.position.x = 15;
    Particles_00.position.z = -20;
    sceneParticles.particles_00 = Particles_00;
    group.add(sceneParticles.particles_00);

    function ShowParticles00(){
        gsap.to(ParticlesMaterial_00.uniforms.uParticleAlpha, { duration: 1.2, value: 1.0, delay: 0 });
    }

    function HideParticles00(){
        gsap.to(ParticlesMaterial_00.uniforms.uParticleAlpha, { duration: 1.2, value: 0.0, delay: 0 });
    }

    function ChangeParticles00Color_0(){
        gsap.to(ParticlesMaterial_00.uniforms.uColorLerp, { duration: 1.2, value: 0.0, delay: 0 });
    }

    function ChangeParticles00Color_1(){
        gsap.to(ParticlesMaterial_00.uniforms.uColorLerp, { duration: 1.2, value: 1.0, delay: 0 });
    }

    SetAnimationFunctions(ShowParticles00, "Particle00Show");
    SetAnimationFunctions(HideParticles00, "Particle00Hide");
    SetAnimationFunctions(ChangeParticles00Color_0, "Particle00ChangeColor0");   
    SetAnimationFunctions(ChangeParticles00Color_1, "Particle00ChangeColor1"); 


    const Particles01Count = 2000;
    const Particles01BoundSize = 10.0;
    const Particles01Positions = new Float32Array(Particles01Count * 3);

    for(let i = 0; i < Particles01Positions.length; i++){
        Particles01Positions[i * 3 + 0] = (Math.random() * 2 - 1.0) * (Math.random() * 2 - 1.0) * Particles01BoundSize * 2.5;
        Particles01Positions[i * 3 + 1] = (Math.random() * 2 - 1.0) * (Math.random() * 2 - 1.0) * Particles01BoundSize * 1.5;
        Particles01Positions[i * 3 + 2] = (Math.random() * 2 - 1.0) * Particles01BoundSize;
    }
    
    const Particles01Geometry = new THREE.BufferGeometry();
    Particles01Geometry.setAttribute('position', new THREE.BufferAttribute(Particles01Positions, 3));

    const ParticlesMaterial_01 = new THREE.ShaderMaterial({
        uniforms: {
            uTime: { value: 0.0 },
            uParticleColor: { value: new THREE.Color( 0x92c70a ) },
            uParticleColor2: { value: new THREE.Color( 0xd2d4a7 ) },
            uParticleAlpha: { value: 0.0 },
            uSize: { value: ParticleSize }
        },
        vertexShader: Particles01VertexShader(),
        fragmentShader: Particles01FragmentShader(),
        transparent: true,        
        depthWrite: false
    });

    const Particles_01 = new THREE.Points(Particles01Geometry, ParticlesMaterial_01);
    Particles_01.position.z = -5.0;
    sceneParticles.particles_01 = Particles_01;
    group.add(sceneParticles.particles_01);

    function ShowParticles01(){
        gsap.to(ParticlesMaterial_01.uniforms.uParticleAlpha, { duration: 1.0, value: 1.0, delay: 0 });
    }

    function HideParticles01(){
        gsap.to(ParticlesMaterial_01.uniforms.uParticleAlpha, { duration: 1.0, value: 0.0, delay: 0 });
    }

    SetAnimationFunctions(ShowParticles01, "Particle01Show");
    SetAnimationFunctions(HideParticles01, "Particle01Hide");

    tickRegister((deltaTime, elapsedTime) => {
        ParticlesMaterial_00.uniforms.uTime.value = elapsedTime;
        ParticlesMaterial_01.uniforms.uTime.value = elapsedTime;
    });
}