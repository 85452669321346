import * as THREE from "three";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

export default function Renderer(scene, camera){
    const canvas = document.getElementById('webgl');

    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    });

    renderer.setClearColor(0x060606, 1);
    renderer.setSize(Math.ceil(window.innerWidth + 1.0), Math.ceil(window.innerHeight + 1.0));
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFShadowMap;
    renderer.outputEncoding = THREE.sRGBEncoding;
    // renderer.render(scene, camera);
    const composer = new EffectComposer( renderer );

    const renderPass = new RenderPass( scene, camera );
    composer.addPass( renderPass );

    // const bloomPass = new UnrealBloomPass();
    // bloomPass.strength = 1.5;
    // bloomPass.threshold = 1.5;
	// bloomPass.radius = 0.0;
    // composer.addPass( bloomPass );

    
    window.addEventListener('resize', () => {  
        renderer.setSize(Math.ceil(window.innerWidth + 1.0), Math.ceil(window.innerHeight + 1.0));
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });
    
    return {
        renderer,
        composer       
    }
}