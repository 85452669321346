export function Stars() {
  return (
    <svg
      width="112"
      height="16"
      viewBox="0 0 112 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_200_279)">
        <path
          d="M8.00004 11.5135L12.12 14.0002L11.0267 9.3135L14.6667 6.16016L9.87337 5.7535L8.00004 1.3335L6.12671 5.7535L1.33337 6.16016L4.97337 9.3135L3.88004 14.0002L8.00004 11.5135Z"
          fill="#F1C21B"
        />
      </g>
      <g clipPath="url(#clip1_200_279)">
        <path
          d="M32 11.5135L36.12 14.0002L35.0267 9.3135L38.6667 6.16016L33.8734 5.7535L32 1.3335L30.1267 5.7535L25.3334 6.16016L28.9734 9.3135L27.88 14.0002L32 11.5135Z"
          fill="#F1C21B"
        />
      </g>
      <g clipPath="url(#clip2_200_279)">
        <path
          d="M56 11.5135L60.12 14.0002L59.0267 9.3135L62.6667 6.16016L57.8734 5.7535L56 1.3335L54.1267 5.7535L49.3334 6.16016L52.9734 9.3135L51.88 14.0002L56 11.5135Z"
          fill="#F1C21B"
        />
      </g>
      <g clipPath="url(#clip3_200_279)">
        <path
          d="M80 11.5135L84.12 14.0002L83.0267 9.3135L86.6667 6.16016L81.8734 5.7535L80 1.3335L78.1267 5.7535L73.3334 6.16016L76.9734 9.3135L75.88 14.0002L80 11.5135Z"
          fill="#F1C21B"
        />
      </g>
      <g clipPath="url(#clip4_200_279)">
        <path
          d="M104 11.5135L108.12 14.0002L107.027 9.3135L110.667 6.16016L105.873 5.7535L104 1.3335L102.127 5.7535L97.3334 6.16016L100.973 9.3135L99.88 14.0002L104 11.5135Z"
          fill="#F1C21B"
        />
      </g>
      <defs>
        <clipPath id="clip0_200_279">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_200_279">
          <rect width="16" height="16" fill="white" transform="translate(24)" />
        </clipPath>
        <clipPath id="clip2_200_279">
          <rect width="16" height="16" fill="white" transform="translate(48)" />
        </clipPath>
        <clipPath id="clip3_200_279">
          <rect width="16" height="16" fill="white" transform="translate(72)" />
        </clipPath>
        <clipPath id="clip4_200_279">
          <rect width="16" height="16" fill="white" transform="translate(96)" />
        </clipPath>
      </defs>
    </svg>
  );
}
