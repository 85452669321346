import { useEffect, useState } from "react";

import "./styles.css";

import { RegisterStateObserver } from "Interfaces/StateInterface.js";

import Img1 from "./assets/img1.png";
import Img2 from "./assets/img2.png";

export default function Screen5(){

    const [displayElement, setDisplayElement] = useState("none");
    const [opacityElement, setOpacityElement] = useState(0);
    const [translateElement1, setTranslateElement1] = useState("-50vh");
    const [translateElement2, setTranslateElement2] = useState("50vh");

    function ShowElement(){
        setDisplayElement("flex");
        setTimeout(() => {
            setOpacityElement(1);   
            setTranslateElement1("0vh"); 
            setTranslateElement2("0vh");     
        }, 500);
    }

    function HideElement(){
        setOpacityElement(0);
        setTranslateElement1("-50vh"); 
        setTranslateElement2("50vh"); 
        setTimeout(() => {
            setDisplayElement("none");            
        }, 1000);        
    }

    useEffect(()=>{
        RegisterStateObserver("screen4", HideElement);
        RegisterStateObserver("screen5", ShowElement);
        RegisterStateObserver("screen6", HideElement);
    },[]);

    return(
        <div className="secao1" style={{ display: displayElement, opacity: opacityElement }}>
            <div className="title" style={{ transform: `translateY(${translateElement1})` }}>Reproduza seus áudios sem preocupação</div>
            <div className="row">
                <div className="col_1">
                    4
                    <img src={Img1} alt=""/><br/>
                    <div>Horas do fone</div>
                </div>
                <div className="col_1 col_2">
                    12
                    <img src={Img2} alt="" className="img_mobile"/><br/>
                    <div>Horas do case</div>
                </div>
                <div></div>
            </div>
            <div className="row_2" style={{ transform: `translateY(${translateElement2})` }}>
                <div className="col">16h</div>
                <div className="col_2">
                    <div className="text_1">Total de reprodução</div>
                    <div className="text_2">Tempo de autonomia de reprodução depende do tipo de música e volume.</div>
                </div>
            </div>
        </div>
    )
}