import * as THREE from "three";

import { Animations } from "Interfaces/StateInterface.js";

export default function SceneMeshes(loader, materialCollection, group, meshesCollection, TickRegister){
    
    let phoneMesh = null;
    let animMixer = null;
    let anim00 = null;
    let anim01 = null;
    let anim02 = null;
    let anim03 = null;
    let anim00reverse = null;
    let anim01reverse = null;
    let anim02reverse = null;
    let anim03reverse = null;
    loader.gltfLoader.load(
        "/assets/models/phones.glb",
        (gltf) => {      

            animMixer = new THREE.AnimationMixer(gltf.scene);
            anim00 = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim0', 0, 30, 30 ); 
            anim01 = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim1', 32, 62, 30 );
            anim02 = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim2', 64, 94, 30 );
            anim03 = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim3', 96, 128, 30 );
            anim00reverse = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim0', 231, 262, 30 );
            anim01reverse = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim1', 197, 228, 30 );
            anim02reverse = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim2', 163, 194, 30 );
            anim03reverse = THREE.AnimationUtils.subclip( gltf.animations[0], 'anim3', 130, 160, 30 );

            let animAction1 = animMixer.clipAction(anim00);
            animAction1.setLoop(THREE.LoopOnce);
            animAction1.clampWhenFinished = true;
            // animAction1.enable = true;

            let  animAction2 = animMixer.clipAction(anim01);
            animAction2.setLoop(THREE.LoopOnce);
            animAction2.clampWhenFinished = true;
            // animAction2.enable = true;

            let animAction3 = animMixer.clipAction(anim02);
            animAction3.setLoop(THREE.LoopOnce);
            animAction3.clampWhenFinished = true;
            // animAction3.enable = true;

            let  animAction4 = animMixer.clipAction(anim03);
            animAction4.setLoop(THREE.LoopOnce);
            animAction4.clampWhenFinished = true;
            animAction4.enable = true;

            
            let animAction1reverse = animMixer.clipAction(anim00reverse);
            animAction1reverse.setLoop(THREE.LoopOnce);
            animAction1reverse.clampWhenFinished = true;
            // animAction1.enable = true;

            let  animAction2reverse = animMixer.clipAction(anim01reverse);
            animAction2reverse.setLoop(THREE.LoopOnce);
            animAction2reverse.clampWhenFinished = true;
            // animAction2.enable = true;

            let animAction3reverse = animMixer.clipAction(anim02reverse);
            animAction3reverse.setLoop(THREE.LoopOnce);
            animAction3reverse.clampWhenFinished = true;
            // animAction3.enable = true;

            let  animAction4reverse = animMixer.clipAction(anim03reverse);
            animAction4reverse.setLoop(THREE.LoopOnce);
            animAction4reverse.clampWhenFinished = true;
            animAction4reverse.enable = true;

            Animations.anim00.push(animAction2);
            Animations.anim00.push(animAction2reverse);
            Animations.anim00.push(animAction1);

            Animations.anim01.push(animAction3);
            Animations.anim01.push(animAction2);
            Animations.anim01.push(animAction3reverse);

            Animations.anim02.push(animAction4);
            Animations.anim02.push(animAction3);
            Animations.anim02.push(animAction4reverse);

            // Animations.anim03.push(animAction4);
            // Animations.anim03.push(animAction3);
            // Animations.anim03.push(animAction4reverse);

            Animations.anim00reverse.push(animAction2reverse);
            Animations.anim00reverse.push(animAction2);
            Animations.anim00reverse.push(animAction3reverse);

            Animations.anim01reverse.push(animAction3reverse);
            Animations.anim01reverse.push(animAction3);
            Animations.anim01reverse.push(animAction4reverse);

            Animations.anim02reverse.push(animAction4reverse);
            Animations.anim02reverse.push(animAction4);
            Animations.anim02reverse.push(animAction3);
            Animations.anim02reverse.push(animAction3reverse);
            // Animations.anim02reverse.push(animAction4reverse);

            // Animations.anim03reverse.push(animAction4reverse);
            // Animations.anim03reverse.push(animAction4);

            setTimeout(() => {
                animAction1.play();                
            }, 1000);

            // setTimeout(() => {
            //     animAction2.play();
            //     animAction1.stop();
            //     setTimeout(() => {
            //         animAction3.play();
            //         animAction2.stop();
            //         setTimeout(() => {
            //             animAction4.play();
            //             animAction3.stop();
            //         }, 3000);
            //     }, 3000);
            // }, 3000);


            TickRegister((deltaTime) => {
                animMixer.update(deltaTime);
            });

            gltf.scene.traverse((child) => {                
                if(child instanceof THREE.Mesh){                    
                    switch(child.name){
                        case 'phonesMesh_1':
                            child.material = materialCollection.PhoneMaterial_00;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                        case 'phonesMesh_2':
                            child.material = materialCollection.PhoneMaterial_01;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                        case 'phonesMesh_3':
                            child.material = materialCollection.PhoneMaterial_02;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                        case 'phonesMesh_4':
                            child.material = materialCollection.PhoneMaterial_03;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                        case 'phonesMesh_5':
                            child.material = materialCollection.PhoneMaterial_04;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                        case 'phonesMesh_6':
                            child.material = materialCollection.PhoneMaterial_05;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                        default:
                            child.material = materialCollection.PhoneMaterial_00;
                            child.castShadow = true;
                            child.receiveShadow = true; 
                            break;
                    }
                    // child.material = materialCollection.PhoneMaterial_00;
                    // child.castShadow = true;
                    // child.receiveShadow = true;                
                }
            });
            
            gltf.scene.scale.set(2.54, 2.54, 2.54);        
            phoneMesh = gltf.scene;

            meshesCollection.phoneMesh = phoneMesh;
            group.add(phoneMesh);

        }
    );   

    // const Plant00Geometry = new THREE.PlaneGeometry( 1, 1 );
    // const Plant00Material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
    // const Plant00Plane = new THREE.Mesh( Plant00Geometry, Plant00Material );
    // group.add( Plant00Plane );    
    
}