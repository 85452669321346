import Renderer from "3D/core/Renderer.js";
import Tick from "3D/core/Tick.js";
import Scene from "3D/core/Scene.js";
import { SetupInput } from "3D/core/Controller";

export default function Main(){

    const updateFrameInstance = Tick();

    // Scene
    const sceneInstance = Scene(updateFrameInstance.subscribeObserver);
    
    // Renderer
    const rendererInstance = Renderer(sceneInstance.scene, sceneInstance.camera);
       
    // Updates renderer every frame
    updateFrameInstance.subscribeObserver(() => {
        //rendererInstance.renderer.render(sceneInstance.scene, sceneInstance.camera);
        rendererInstance.composer.render();
    });  

    updateFrameInstance.tick(); 
       
    SetupInput();
}