import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { NextState } from "Interfaces/StateInterface.js";

export default function Loaders() {    

    const loadingManager = new THREE.LoadingManager(
        // Completed
        () =>
        {
            // console.log("Scene loaded");
            NextState();
        },    
        // Progress
        (itemUrl, itemsLoaded, itemsTotal) =>
        {
            // const progressRatio = itemsLoaded / itemsTotal;        
        }
    );

    const textureLoader = new THREE.TextureLoader(loadingManager);

    const gltfLoader = new GLTFLoader(loadingManager);    

    return {
        loadingManager,
        textureLoader,
        gltfLoader
    }
}