import * as THREE from "three";

export default function SceneMaterials(textureLoader){
    
    // Materials
    const PhoneMaterial_00 = new THREE.MeshStandardMaterial({
        color: 0x0e0e0e,
        roughness: 0.5,
        metalness: 0.6, 
    });

    const PhoneMaterial_01 = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        roughness: 0.5,
        metalness: 0.6,        
    });

    const PhoneMaterial_02 = new THREE.MeshStandardMaterial({
        color: 0xe4fe0b,
        roughness: 0.0,
        metalness: 0.0,
        emissive: 0xe4fe0b,
        emissiveIntensity: 2.0
    });
    const PhoneMaterial_03 = new THREE.MeshStandardMaterial({
        color: 0xe4fe0b,
        roughness: 0.6,
        metalness: 0.0,
    });

    const PhoneMaterial_04 = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        roughness: 0.5,
        metalness: 0.6, 
    });

    const PhoneMaterial_05 = new THREE.MeshStandardMaterial({
        color: 0x9b5b1c,
        roughness: 0.5,
        metalness: 1.0, 
    });  

    textureLoader.load("assets/textures/phones_00_tex.png",
        (texture)=>{
            PhoneMaterial_04.map = texture; 
            PhoneMaterial_04.map.minFilter = THREE.LinearFilter;
        },
        ()=>{},
        ()=>{}
    );
    textureLoader.load("assets/textures/case_00_tex.png",
        (texture)=>{
            PhoneMaterial_01.map = texture; 
            PhoneMaterial_01.map.minFilter = THREE.LinearFilter;
        },
        ()=>{},
        ()=>{}
    );
    

    return {
        textures: {
            
        },
        materials: {
            PhoneMaterial_00,
            PhoneMaterial_01,
            PhoneMaterial_02,
            PhoneMaterial_03,
            PhoneMaterial_04,
            PhoneMaterial_05
        }
    }
}