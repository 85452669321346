import { useEffect, useState } from "react";
import * as THREE from "three";

import { RegisterStatePositionObserver, RegisterStateObserver } from "Interfaces/StateInterface.js";

import "./style.css";

import TxtScreen3 from "Assets/txt_screen3.png";

export default function Screen3(){

    const [imageWidth, setImageWidth] = useState("100%");
    const [offsetX, setOffsetX] = useState("0px");
    const [offsetY, setOffsetY] = useState("0px");
    const [display, setDisplay] = useState("none");
    const [opacity, setOpacity] = useState(0);

    const pointPosition = new THREE.Vector3(-2.75, 1.8, 18.5);

    function CalculatePosition(camera, proportion){        
        const screenPosition = pointPosition.clone();        
        screenPosition.project(camera);
        setOffsetX(((((1 + screenPosition.x) / 2) * window.innerWidth) - (20 * proportion)) + "px");
        setOffsetY(((((1 + -screenPosition.y) / 2) * window.innerHeight) - (20 * proportion)) + "px");
    }

    function ShowElement(){
        setDisplay("block");
        setTimeout(() => {
            setOpacity(1); 
        }, 1000);
    }

    function HideElement(){
        setOpacity(0);
        setTimeout(() => {
            setDisplay("none");
        }, 1500);
        
    }

    useEffect(() => {
        window.addEventListener('resize', () => {    
            setImageWidth((window.innerWidth * 0.35) + "px");
        });        
        setImageWidth((window.innerWidth * 0.35) + "px"); 
        
        RegisterStatePositionObserver("screen2", CalculatePosition);
        RegisterStatePositionObserver("screen3", CalculatePosition);
        RegisterStatePositionObserver("screen4", CalculatePosition);
        RegisterStateObserver("screen2", HideElement);
        RegisterStateObserver("screen3", ShowElement);
        RegisterStateObserver("screen4", HideElement);
    }, []);

    return (
        <div className="screen3" id="screen3" style={{ width: imageWidth, top: offsetY, left: offsetX, display: display, opacity: opacity }}  >
            <img alt="" src={TxtScreen3} />
        </div>
    )
}