import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Main from "3D/core/Main.js";

import Screen1 from 'Components/Screen1';
import Screen2 from 'Components/Screen2';
import Screen3 from 'Components/Screen3';
import Screen4 from 'Components/Screen4';
import Screen5 from 'Components/Screen5';
import Screen6 from 'Components/Screen6';
import Screen7 from 'Components/Screen7';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <App />
    <Screen1 />
    <Screen2 />
    <Screen3 />
    <Screen4 />
    <Screen5 />
    <Screen6 />
    <Screen7 />
  </React.Fragment>
);
Main();
