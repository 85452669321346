import * as THREE from "three";
import Loaders from "3D/core/Loaders.js";
import PerspectiveCamera from "3D/camera/PerspectiveCamera.js";
import AmbientLight from "3D/sceneComponents/lights/AmbientLight.js";
import DirectionalLight from "3D/sceneComponents/lights/DirectionalLight.js";
import SceneMaterials from "3D/sceneComponents/materials/SceneMaterials.js";
import SceneMeshes from "3D/sceneComponents/meshes/SceneMeshes.js";
import SceneParticles from "3D/sceneComponents/particles/SceneParticles.js";

export default function Scene(TickRegister){    

    const scene = new THREE.Scene();

    // Loader
    const loaders = Loaders();

    // Groups
    const scenarioGroup = new THREE.Group();
    scene.add(scenarioGroup);

    // Camera
    const mainCamera = PerspectiveCamera(35, 0.1, 100, {x: 0.0, y: 0.8, z: 27.0},  {x: 0.0, y: 0.5, z: 0.0});
    scenarioGroup.add(mainCamera.cameraActor);

    // Lights
    const ambientLight = AmbientLight(0xffffff, 0.3);
    scene.add(ambientLight.light);

    const directionalLight = DirectionalLight(0xffffff, 0.7, new THREE.Vector3(3.0, 3.0, 5.0), true);
    scenarioGroup.add(directionalLight.light);

    const directionalLight2 = DirectionalLight(0xfafaff, 0.6, new THREE.Vector3(-5.0, -5.0, -10.0), true);
    scenarioGroup.add(directionalLight2.light);
    

    // Textures and Materials
    const sceneMaterials = SceneMaterials(loaders.textureLoader);

    // Meshes
    const scenarioMeshes = {};
       
    SceneMeshes(loaders, sceneMaterials.materials, scenarioGroup, scenarioMeshes, TickRegister);    

    // Particle
    const scenarioParticles = {};

    SceneParticles(scenarioGroup, scenarioParticles, TickRegister);

    return {
        scene: scene,
        camera: mainCamera.cameraActor,        
    }

}