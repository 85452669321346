import * as THREE from "three";

export default function DirectionalLight(color, intensity, position, castShadow){

    const directionalLight = new THREE.DirectionalLight(color, intensity);
    directionalLight.position.set(position.x, position.y, position.z);

    if(castShadow){
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = 2048;
        directionalLight.shadow.mapSize.height = 2048;
        directionalLight.shadow.camera.right = 20;
        directionalLight.shadow.camera.left = -20;
        directionalLight.shadow.camera.top = 20;
        directionalLight.shadow.camera.bottom = -20;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 50;
        directionalLight.shadow.radius = 5;
        directionalLight.shadow.normalBias = -0.03
    }

    function SetIntensity(intensity){
        directionalLight.intensity = intensity;
    }

    function SetColor(color){
        directionalLight.color = color;
    }

    function SetPosition(position){
        directionalLight.position.set(position.x, position.y, position.z);
    }

    function SetTarget(target){
        directionalLight.target = target;
    }

    return {
        light: directionalLight,
        SetIntensity,
        SetColor,
        SetPosition,
        SetTarget
    };
}