import { useEffect, useState, Fragment } from "react";
import * as THREE from "three";

import { RegisterStatePositionObserver, RegisterStateObserver } from "Interfaces/StateInterface.js";

import "./style.css";

import TxtScreen1 from "Assets/txt_screen1.png";
import Plant_00 from "Assets/plant_00.png";
import Plant_01 from "Assets/plant_01.png";

export default function Screen1(){

    const [imageWidth, setImageWidth] = useState("100%");
    const [offsetX, setOffsetX] = useState("0px");
    const [offsetY, setOffsetY] = useState("0px");
    const [display, setDisplay] = useState("none");
    const [opacity, setOpacity] = useState(0);
    const [plant0Width, setPlant0Width] = useState("100%");
    const [plant0OffsetX, setPlant0OffsetX] = useState("-10px");
    const [plant0OffsetY, setPlant0OffsetY] = useState("-10px");
    const [plant0Display, setPlant0Display] = useState("none");
    const [plant0Opacity, setPlant0Opacity] = useState(0);
    const [plant1Width, setPlant1Width] = useState("100%");
    const [plant1OffsetX, setPlant1OffsetX] = useState("-10px");
    const [plant1OffsetY, setPlant1OffsetY] = useState("-10px");
    const [plant1Display, setPlant1Display] = useState("none");
    const [plant1Opacity, setPlant1Opacity] = useState(0);

    const pointPosition = new THREE.Vector3(-0.0, -4.2, 15.5);

    function CalculatePosition(camera, proportion){        
        const screenPosition = pointPosition.clone();        
        screenPosition.project(camera);
        setOffsetX(((((1 + screenPosition.x) / 2) * window.innerWidth) - (422 * proportion)) + "px");
        setOffsetY(((((1 + -screenPosition.y) / 2) * window.innerHeight) - (422 * proportion)) + "px");
        setPlant0OffsetX((((screenPosition.x * 50) - (1 * proportion)) * 10) + "px");
        setPlant0OffsetY((((screenPosition.y * 30) - (1 * proportion)) * 10) + 380 + "px");
        setPlant1OffsetX((((-screenPosition.x * 50) - (1 * proportion)) * 10) + "px");
        setPlant1OffsetY((((screenPosition.y * 30) - (1 * proportion)) * 10) + 380 + "px");
    }

    function ShowElement(){
        setDisplay("block");
        setPlant0Display("block");
        setPlant1Display("block");
        setTimeout(() => {
            setOpacity(1); 
            setPlant0Opacity(1);
            setPlant1Opacity(1);
        }, 1000);
    }

    function HideElement(){
        setOpacity(0);
        setPlant0Opacity(0);
        setPlant1Opacity(0);
        setTimeout(() => {
            setDisplay("none");
            setPlant0Display("none");
            setPlant1Display("none");
        }, 1500);
        
    }

    useEffect(() => {
        window.addEventListener('resize', () => {    
            setImageWidth((window.innerWidth * 0.4) + "px");
            setPlant0Width((window.innerWidth * 0.26) + "px");
            setPlant1Width((window.innerWidth * 0.26) + "px");
        });        
        setImageWidth((window.innerWidth * 0.4) + "px"); 
        setPlant0Width((window.innerWidth * 0.26) + "px");
        setPlant1Width((window.innerWidth * 0.26) + "px");
        
        RegisterStatePositionObserver("screen1", CalculatePosition);
        RegisterStatePositionObserver("screen2", CalculatePosition);       
        RegisterStateObserver("screen1", ShowElement);
        RegisterStateObserver("screen2", HideElement);
        
    }, []);

    return (
        <Fragment>
            <div className="screen1" id="screen1" style={{ width: imageWidth, top: offsetY, left: offsetX, display: display, opacity: opacity }}  >
                <img alt="" src={TxtScreen1} />
            </div>
            <div className="screen1plant0" id="screen1plant0" style={{ width: plant0Width, bottom: plant0OffsetY, left: plant0OffsetX, display: plant0Display, opacity: plant0Opacity }}  >
                <img alt="" src={Plant_00} />               
            </div>
            <div className="screen1plant1" id="screen1plant1" style={{ width: plant1Width, bottom: plant1OffsetY, right: plant1OffsetX, display: plant1Display, opacity: plant1Opacity }}  >
                <img alt="" src={Plant_01} />
            </div>
        </Fragment>
    )
}