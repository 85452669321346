import { NextState, PreviousState } from "Interfaces/StateInterface.js";

export function SetupInput(){
    window.addEventListener('wheel',(event) => {
        if(event.deltaY < 0){
            PreviousState();
        }else{
            NextState();
        }
    });
}