import * as THREE from "three";

export default function AmbientLight(color, intensity){

    const ambientLight = new THREE.AmbientLight(color, intensity);

    function SetIntensity(intensity){
        ambientLight.intensity = intensity;
    }

    function SetColor(color){
        ambientLight.color = color;
    }

    return {
        light: ambientLight,
        SetIntensity,
        SetColor
    };
}